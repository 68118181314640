import { baseUrl, endpoints } from './config';

export default class AffiliateService {
	static async fetchUrl(method, initOptions = {}) {
		const options = {
			...initOptions,
		};
		const response = await fetch(`${baseUrl}${method}`, options);
		return response.json();
	}
	static getTokenObject(xSID) {
		return {
			headers: { 'X-Sid': xSID },
		};
	}
	static handleError(error, methodName) {
		console.log(`${methodName} failed with error: `, error);
	}

	// GET
	static getDashboardData = async ({ xSID, id }) => {
		const options = {
			method: 'GET',
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(
			`${endpoints.dashboard}${id ? `/${id}` : ''}`,
			options
		).catch((error) => this.handleError(error, 'getDashboardData'));
	};
	// static getWalletData = async ({ xSID, id }) => {
	// 	const options = {
	// 		method: 'GET',
	// 		...this.getTokenObject(xSID),
	// 	};
	// 	return this.fetchUrl(
	// 		`${endpoints.wallet}${id ? `/${id}` : ''}`,
	// 		options
	// 	).catch((error) => this.handleError(error, 'getWalletData'));
	// };
	static getWalletBrands = async ({ xSID }) => {
		const options = {
			method: 'GET',
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.walletAdv, options).catch((error) => this.handleError(error, 'getWalletBrands'));
	};
	static getCountry = async () => {
		const options = { method: 'GET' };
		return this.fetchUrl(endpoints.country, options).catch((error) =>
			this.handleError(error, 'getCountry')
		);
	};
	static getProducts = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.products, options).catch((error) =>
			this.handleError(error, 'getProducts')
		);
	};
	static getParams = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.params, options).catch((error) =>
			this.handleError(error, 'getParams')
		);
	};
	static getCountryStates = async ({ xSID, c_codes }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.states}?country_code=${c_codes}`,
			options
		).catch((error) => this.handleError(error, 'getCountryStates'));
	};
	static getCurrency = async () => {
		const options = { method: 'GET' };
		return this.fetchUrl(endpoints.currency, options).catch((error) =>
			this.handleError(error, 'getCurrency')
		);
	};
	static getTrafficTag = async () => {
		const options = { method: 'GET' };
		return this.fetchUrl(endpoints.traffic_tag, options).catch((error) =>
			this.handleError(error, 'getTrafficTag')
		);
	};
	static getBrands = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.brands, options).catch((error) =>
			this.handleError(error, 'getBrands')
		);
	};
	static getSources = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.sources, options).catch((error) =>
			this.handleError(error, 'getSources')
		);
	};
	static getCommissions = async ({ xSID, id }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			id
				? `${endpoints.sources}/${id}${endpoints.commission}`
				: endpoints.commission,
			options
		).catch((error) => this.handleError(error, 'getCommissions'));
	};
	static getCostTypes = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.costTypes, options).catch((error) =>
			this.handleError(error, 'getCostTypes')
		);
	};
	static getUserData = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.user, options).catch((error) =>
			this.handleError(error, 'getUserData')
		);
	};
	static logout = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.logout, options).catch((error) =>
			this.handleError(error, 'logout')
		);
	};
	static resendLink = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.resendLink, options).catch((error) =>
			this.handleError(error, 'resendLink')
		);
	};
	static loginAs = async (token) => {
		const options = { method: 'GET' };
		return this.fetchUrl(`${endpoints.loginAs}/${token}`, options).catch(
			(error) => this.handleError(error, 'loginAs')
		);
	};
	static getTrackingLinks = async ({ xSID, id }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(`${endpoints.trackingLinks}/${id}`, options).catch(
			(error) => this.handleError(error, 'getTrackingLinks')
		);
	};
	static getMacros = async ({ xSID, id }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(`${endpoints.macros_get}/${id}`, options).catch(
			(error) => this.handleError(error, 'getMacros')
		);
	};
	static getPostbacks = async ({ xSID, id }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(`${endpoints.postbacks}/${id}`, options).catch(
			(error) => this.handleError(error, 'getPostbacks')
		);
	};
	static getCommissionPlans = async ({ xSID, id }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.commission}?source_id=${id}`,
			options
		).catch((error) => this.handleError(error, 'getCommissionPlans'));
	};
	static getWalletSettings = async ({ xSID, advID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.walletSettings}?adv_id=${advID}`,
			options
		).catch((error) => this.handleError(error, 'getWalletSettings'));
	};
	static getCompliance = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.affCompliance, options).catch((error) =>
			this.handleError(error, 'getCompliance')
		);
	};
	static getBeneficiary = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.getBeneficiary, options).catch((error) =>
			this.handleError(error, 'getBeneficiary')
		);
	};
	static createBeneficiary = async (xSID) => {
		const options = { method: 'POST', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.createBeneficiary, options).catch(
			(error) => this.handleError(error, 'createBeneficiary')
		);
	};
	static getManagerData = async (xSID) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.manager, options).catch((error) =>
			this.handleError(error, 'getManagerData')
		);
	};
	static getEvents = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.events, options).catch((error) =>
			this.handleError(error, 'getEvents')
		);
	};
	static getNotificationsPreferences = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.affPreferences, options).catch((error) =>
			this.handleError(error, 'getNotificationsPreferences')
		);
	};
	static getBalanceAnalytic = async ({
		xSID,
		range,
		adv_id = null,
	}) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(
			`${endpoints.balanceAnalytic}${range}${adv_id ? `&adv_id=${adv_id}` : ''}`,
			options
		).catch((error) => this.handleError(error, 'getBalanceAnalytic'));
	};
	static getRevenueFlow = async ({ xSID }) => {
		const options = { method: 'GET', ...this.getTokenObject(xSID) };
		return this.fetchUrl(endpoints.revenueFlow, options).catch((error) =>
			this.handleError(error, 'getRevenueFlow')
		);
	};

	// POST
	static login = async (userData) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(userData),
		};
		return this.fetchUrl(endpoints.login, options).catch((error) =>
			this.handleError(error, 'login')
		);
	};
	static signup = async ({ data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		return this.fetchUrl(endpoints.signup, options).catch((error) =>
			this.handleError(error, 'signup')
		);
	};
	static signDocument = async ({ xSID, template_id }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(template_id),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.signDocument, options).catch((error) =>
			this.handleError(error, 'signDocument')
		);
	};
	static forgotPassword = async (data) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		return this.fetchUrl(endpoints.forgotPassword, options).catch((error) =>
			this.handleError(error, 'forgotPassword')
		);
	};
	static resetPassword = async (data) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		return this.fetchUrl(endpoints.resetPassword, options).catch((error) =>
			this.handleError(error, 'resetPassword')
		);
	};
	static validateToken = async (data) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		return this.fetchUrl(endpoints.validateToken, options).catch((error) =>
			this.handleError(error, 'validateToken')
		);
	};
	static sendContactUsForm = async ({ xSID, data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.contactUs, options).catch((error) =>
			this.handleError(error, 'sendContactUsForm')
		);
	};
	static updateWalletSetting = async ({ xSID, data, advID }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(`${endpoints.walletSettings}?adv_id=${advID}`, options).catch((error) =>
			this.handleError(error, 'updateWalletSetting')
		);
	};
	static updateMacros = async ({ xSID, data, id }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(`${endpoints.macros_post}/${id}`, options).catch(
			(error) => this.handleError(error, 'updateMacros')
		);
	};
	static createPostback = async ({ xSID, data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.postbacks, options).catch((error) =>
			this.handleError(error, 'createPostback')
		);
	};
	static changePassword = async ({ xSID, data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.changePassword, options).catch((error) =>
			this.handleError(error, 'changePassword')
		);
	};
	static updateNotificationsPreferences = async ({ xSID, data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.affPreferences, options).catch(
			(error) => this.handleError(error, 'updateNotificationsPreferences')
		);
	};

	// PATCH
	static updateAffiliate = async ({ xSID, data }) => {
		const options = {
			method: 'PATCH',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.updateAffiliate, options).catch(
			(error) => this.handleError(error, 'updateAffiliate')
		);
	};
	static updateProfile = async ({ xSID, data }) => {
		const options = {
			method: 'PATCH',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.updateProfile, options).catch((error) =>
			this.handleError(error, 'updateProfile')
		);
	};
	static createBenefeciary = async ({ xSID, data }) => {
		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.benefeciary, options).catch((error) =>
			this.handleError(error, 'createBenefeciary')
		);
	};
	static updatePostback = async ({ xSID, data }) => {
		const options = {
			method: 'PATCH',
			body: JSON.stringify(data),
			...this.getTokenObject(xSID),
		};
		return this.fetchUrl(endpoints.postbacks, options).catch((error) =>
			this.handleError(error, 'updatePostback')
		);
	};
}
