export const host = window.location.hostname;
export const baseUrl = `https://${host}/api`;
export const endpoints = {
    benefeciary: '/beneficiary',
    getBeneficiary: '/affiliate/getBeneficiary',
    brands: '/brand',
    dashboard: '/dashboard',
    balanceAnalytic: '/graph/clicks_daily',
    // balanceAnalytic: '/graph/earnings_daily',
    affPreferences: '/affiliate_notification_preference',
    login: '/affiliate/login',
    loginAs: '/affiliate/login_as',
    signup: '/affiliate/register',
    events: '/event',
    country: '/country',
    currency: '/currency',
    costTypes: '/data/cost_type',
    createBeneficiary: '/beneficiary/create',
    sources: '/source',
    commission: '/commission_plan',
    traffic_tag: '/traffictag',
    changePassword: '/password/change',
    forgotPassword: '/password/forgot',
    validateToken: '/password/validate',
    resetPassword: '/password/reset',
    contactUs: '/help',
    logout: '/affiliate/logout',
    postbacks: '/postback',
    trackingLinks: '/marketing/links',
    manager: '/manager',
    macros_get: '/marketing/macros',
    macros_post: '/network/macros',
    states: '/state',
    products: '/product',
    params: '/param',
    user: '/affiliate/info',
    // wallet: '/wallet', // mock
    walletAdv: '/wallet/adv_wallet',
    walletSettings: '/wallet/wallet_settings',
    revenueFlow: '/graph/revenue_flow',
    resendLink: '/affiliate/send_email_verification',
    signDocument: '/compliance/sign',
    affCompliance: '/compliance/template',
    updateAffiliate: '/affiliate/update',
    updateProfile: '/profile',
};
