import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { logout } from './auth';
import AffiliateService from '../../services/affiliates.service';
import { walletActions } from '../actions/wallet';
import { stringifyText } from '../../utils/text';
import { userActions } from '../actions/user';

function* getWalletSettings({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getWalletSettings,
		payload,
		walletActions.getWalletSettingsSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* updateWalletSetting({ payload }) {
	yield put(uiActions.startLoaderAction());
	const settings = yield call(
		handleResponse,
		AffiliateService.updateWalletSetting,
		payload,
	);
	yield put(uiActions.endLoaderAction());
	yield put(uiActions.toggleModalOpenAction(false));
	yield !settings?.error &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Wallet limit was successfully updated',
			})
		);
	yield payload?.advID && call(
		handleResponse,
		AffiliateService.getWalletSettings,
		payload,
		walletActions.getWalletSettingsSuccessAction
	);
}
function* getBeneficiary({ payload }) {
	yield call(
		handleResponse,
		AffiliateService.getBeneficiary,
		payload,
		userActions.getBeneficiarySuccessAction
	);
}
function* getRevenueFlow({ payload }) {
	yield call(
		handleResponse,
		AffiliateService.getRevenueFlow,
		payload,
		walletActions.getRevenueFlowSuccessAction
	);
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? onSuccess && put(onSuccess(response?.data))
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	yield put(walletActions.setErrorAction());
	yield put(
		uiActions.setAlertAction({
			show: true,
			severity: 'error',
			body: errorText,
		})
	);
	yield put(uiActions.endLoaderAction());
	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	}
}

function* watchWallet() {
	yield takeLatest(walletActions.getWalletSettingsAction.type, getWalletSettings);
	yield takeLatest(walletActions.updateWalletSettingAction.type, updateWalletSetting);
	yield takeLatest(walletActions.getBeneficiaryAction.type, getBeneficiary);
	yield takeLatest(walletActions.getRevenueFlowAction.type, getRevenueFlow);
}
function* rootWallet() {
	yield fork(watchWallet);
}

export { rootWallet };
